<template>
<div :key="`key_${key}`">
    <div
        v-if="group && !showChatSettings"
        class="kt-chat"
    >
        <div class="kt-portlet kt-portlet--height-fluid kt-portlet--head-lg">
            <div class="kt-portlet__head">
                <div class="kt-chat__head ">
                    <div class="kt-chat__left">
                        <button
                            v-if="isPopUp"
                            type="button"
                            class="btn btn-clean btn-sm btn-icon btn-icon-md"
                            @click.stop.prevent="closePopupChat"
                        >
                            <i class="la la-close" />
                        </button>
                    </div>
                    <div class="kt-chat__center">
                        <div class="kt-chat__label">
                            <a
                                href="#"
                                class="kt-chat__title"
                                :class="{'chat-group-name': (group.groupName && group.groupName.length > 200) || altGroupName.length > 200}"
                            >{{ group.groupName || altGroupName }}</a>
                            <span class="kt-chat__status">
                                {{ group.members.length }} Members
                            </span>
                        </div>
                    </div>
                    <div class="kt-chat__right">
                        <button
                            type="button"
                            class="btn btn-clean btn-sm btn-icon btn-icon-md"
                            @click.stop.prevent="showChatSettings = !showChatSettings"
                        >
                            <i class="fa fa-cog" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div
                    id="chat"
                    class="kt-scroll"
                    style="height: 244px; overflow-x: hidden; overflow-y: auto;"
                >
                    <div class="kt-chat__messages">
                        <div
                            v-for="(message, idx) in messages"
                            :key="`message_${message.communicationGroupMessageId}_${idx}`"
                            class="kt-chat__message"
                            :class="{'kt-chat__message--right': message.fromMe}"
                        >
                            <div
                                v-if="message.fromMe"
                                class="kt-chat__user"
                            >
                                <span class="kt-chat__datetime">{{ message.createdAt }}</span>
                                <a
                                    href="#"
                                    class="kt-chat__username"
                                >{{ message.user ? `${message.user.firstName} ${message.user.lastName}` : 'Unknown User' }}</a>
                                <span class="kt-media kt-media--circle kt-media--sm">
                                    <UserAvatar
                                        :avatar-user="message.user || {}"
                                        style="max-width: 40px; width: 40px; height: 40px;"
                                    >
                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                            {{ message.user ? `${message.user.lastName.substring(0,2)}` : '-' }}
                                        </div>
                                    </UserAvatar>
                                </span>
                            </div>
                            <div
                                v-else
                                class="kt-chat__user"
                            >
                                <span class="kt-media kt-media--circle kt-media--sm">
                                    <UserAvatar
                                        :avatar-user="message.user || {}"
                                        style="max-width: 40px; width: 40px; height: 40px;"
                                    >
                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                            {{ message.user ? `${message.user.lastName.substring(0,2)}` : '-' }}
                                        </div>
                                    </UserAvatar>
                                </span>
                                <a
                                    href="#"
                                    class="kt-chat__username"
                                >{{ message.user ? `${message.user.firstName} ${message.user.lastName}` : 'Unknown User' }}</a>
                                <span class="kt-chat__datetime">{{ message.createdAt }}</span>
                            </div>

                            <div
                                class="kt-chat__text"
                                :class="{'kt-bg-light-brand': message.fromMe, 'kt-bg-light-success': !message.fromMe}"
                            >
                                {{ message.message }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="ps__rail-x"
                        style="left: 0px; bottom: 0px;"
                    >
                        <div
                            class="ps__thumb-x"
                            tabindex="0"
                            style="left: 0px; width: 0px;"
                        />
                    </div><div
                        class="ps__rail-y"
                        style="top: 0px; height: 244px; right: -2px;"
                    >
                        <div
                            class="ps__thumb-y"
                            tabindex="0"
                            style="top: 0px; height: 48px;"
                        />
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
                <div class="kt-chat__input">
                    <div class="kt-chat__editor">
                        <textarea
                            v-model="newMessage"
                            style="height: 50px"
                            placeholder="Type here..."
                        />
                    </div>
                    <div class="kt-chat__toolbar">
                        <div class="kt_chat__tools" />
                        <div class="kt_chat__actions">
                            <button
                                type="button"
                                class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply"
                                @click.stop.prevent="sendMessage"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else-if="group && showChatSettings"
        class="kt-portlet kt-portlet--height-fluid"
    >
        <div class="kt-portlet__head kt-portlet__head--noborder">
            <div class="kt-portlet__head-label">
                <button
                    class="btn btn-clean"
                    @click.prevent.stop="showChatSettings = !showChatSettings"
                >
                    Back to Chat
                </button>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-actions">
                    <a
                        href="#"
                        class="btn btn-clean btn-bold"
                        @click.stop.prevent="saveGroupChanges"
                    >
                        Save
                    </a>
                </div>
            </div>
        </div>
        <div
            class="kt-portlet__body scroll-list"
        >
            <div class="form-group">
                <label>Change Group Name</label>
                <input
                    v-model="newGroupName"
                    type="text"
                    class="form-control"
                    placeholder="Group Name"
                >
            </div>
            <div
                v-if="isGroupOwner"
                class="form-group"
            >
                <div>
                    <label>Add New Members</label>
                    <SearchRecipients
                        :on-selected="memberAdded"
                        :school-staff-id="schoolStaffId"
                        :student-enrollment-id="null"
                        :allow-school-wide="false"
                        :allow-my-students="false"
                        :show-courses="false"
                        :show-home-rooms="false"
                        :show-grade-levels="false"
                        :show-student-groups="false"
                        :show-students="false"
                        :show-guardians="false"
                        :show-teachers="true"
                    />

                    <div class="tab-content p-3">
                        <div class="kt-widget4">
                            <div
                                v-for="(user, idx) in newMembers"
                                :key="`new_group_user_${idx}`"
                                class="kt-widget4__item"
                            >
                                <a
                                    href="#"
                                    class="mr-3"
                                    @click.stop.prevent="removeUser(idx)"
                                >
                                    <i class="la la-close" />
                                </a>

                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                    <UserAvatar :avatar-user="user || {}">
                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                            {{ user ? user.firstName.substring(0, 2) : '-' }}
                                        </div>
                                    </UserAvatar>
                                </div>

                                <div class="kt-widget4__info">
                                    <a
                                        href="#"
                                        class="kt-widget4__username"
                                        @click.stop.prevent="removeUser(idx)"
                                    >
                                        {{ user.name }}
                                    </a>
                                    <p class="kt-widget4__text">
                                        {{ user.subName }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <label>Current Members</label>
                    <div class="tab-content p-3">
                        <div class="kt-widget4">
                            <div
                                v-for="(member, idx) in members"
                                :key="`group_userlist_user_${idx}`"
                                class="kt-widget4__item"
                            >
                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                    <UserAvatar :avatar-user="member.user || {}">
                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                            {{ member.user && member.user.lastName ? member.user.lastName.substring(0, 2) : '-' }}
                                        </div>
                                    </UserAvatar>
                                </div>

                                <div class="kt-widget4__info">
                                    <a
                                        href="#"
                                        class="kt-widget4__username"
                                    >
                                        {{ member.user ? `${member.user.firstName || ''} ${member.user.lastName || ''}` : 'Unknown Member' }}
                                    </a>
                                    <p class="kt-widget4__text">
                                        {{ member.user ? member.user.role || '' : '' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="kt-chat"
    >
        <div class="kt-portlet kt-portlet--height-fluid kt-portlet--head-lg">
            <div class="kt-portlet__head">
                <div class="kt-chat__head ">
                    <div class="kt-chat__left" />
                    <div class="kt-chat__center">
                        <div class="kt-chat__label">
                            <a
                                href="#"
                                class="kt-chat__title"
                            >No Group Selected</a>
                        </div>
                    </div>
                    <div class="kt-chat__right" />
                </div>
            </div>
            <div
                class="kt-portlet__body"
                style="height: 480px;"
            />
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import async from 'async';
import UserAvatar from '../UserAvatar.vue';
import SearchRecipients from '../SearchRecipients.vue';
import Types from '../../store/Types';
import teacherMixins from '../../store/mixins/teacherMixins';
import studentMixins from '../../store/mixins/studentMixins';
import * as network from '../../network';

export default {
    name: 'Chat',
    components: {
        SearchRecipients,
        UserAvatar,
    },
    mixins: [teacherMixins, studentMixins],
    props: {
        isPopUp: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            key: 0,
            newMessage: '',
            saving: false,
            showChatSettings: false,
            newGroupName: null,
            newMembers: [],
            groupMembers: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId || null;
        },
        group() {
            return this.$store.state.communication.communicationGroup || null;
        },
        groupMemberId() {
            const { group, user } = this;
            if (!group) return null;

            const { school } = user;
            const { schoolStaffId, studentId, studentGuardianId } = school;
            const { members } = group;

            const member = members.find((m) => {
                if (schoolStaffId) return m.schoolStaffId == schoolStaffId;
                if (studentGuardianId) return m.studentGuardianId == studentGuardianId;
                if (studentId) return m.studentId == studentId;
                return false;
            }) || null;

            return member ? member.communicationGroupMemberId : null;
        },
        messages() {
            const { group, groupMemberId } = this;
            if (!group) return [];

            const { members } = group;

            const messages = group.messages.slice();
            return messages.map((msg) => {
                const message = { ...msg };

                const member = members.find((m) => m.communicationGroupMemberId == message.communicationGroupMemberId) || null;
                message.member = member;
                message.user = member ? member.user : null;

                message.fromMe = message.communicationGroupMemberId == groupMemberId;
                message.createdAt = moment(message.created).format('MMM DD, YYYY hh:mm A');
                return message;
            });
        },
        teachers() {
            return this.$_teacherMixins_getTeachers();
        },
        students() {
            return this.$_studentMixins_getStudents();
        },
        guardians() {
            return this.$store.state.database.studentGuardians;
        },
        members() {
            const {
                group, groupMembers,
                students, guardians, teachers,
            } = this;
            if (!group) return [];

            return group.members.map((m) => {
                const member = { ...m };
                const { schoolStaffId, studentGuardianId, studentId } = member;
                if (member.user) return member;
                if (schoolStaffId) {
                    const staff = teachers.find((t) => t.schoolStaffId == schoolStaffId) || null;
                    if (staff) member.user = staff;
                    else member.user = groupMembers.find((gm) => gm.schoolStaffId == schoolStaffId) || null;
                } else if (studentGuardianId) {
                    const guardian = guardians.find((g) => g.studentGuardianId == studentGuardianId) || null;
                    if (guardian) member.user = guardian;
                    else member.user = groupMembers.find((gm) => gm.studentGuardianId == studentGuardianId) || null;
                } else if (member.studentId) {
                    const student = students.find((s) => s.studentId == studentId);
                    if (student) member.user = student;
                    else member.user = groupMembers.find((gm) => gm.studentId == studentId) || null;
                }
                return member;
            });
        },
        altGroupName() {
            const { groupMembers } = this;
            return groupMembers.map((m) => `${m.firstName || ''} ${m.lastName || ''}`).join(', ');
        },
        isGroupOwner() {
            const { group, schoolStaffId } = this;
            if (!group || !schoolStaffId) return false;

            return group.schoolStaffId == schoolStaffId;
        },
    },
    watch: {
        group() {
            this.key += 1;
            this.getGroupMembers();
            this.setRead();
            this.scrollToChatEnd();
            this.setGroupName();
        },
    },
    methods: {
        setRead() {
            const v = this;
            const {
                user, group, groupMemberId,
            } = v;
            if (!group) return;

            const { messages, communicationGroupId } = group;

            const unreadMessages = messages.filter((msg) => {
                const { readBy } = msg;
                if (!readBy.includes(groupMemberId)) return true;
                return false;
            }).map((msg) => msg.communicationGroupMessageId);

            if (!unreadMessages.length) return;

            const { school } = user;
            const { schoolId, schoolTermId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    communicationGroupId,
                },
                body: {
                    params: {
                        communicationGroupMemberId: groupMemberId,
                        communicationGroupMessageIds: unreadMessages,
                    },
                },
            };
            network.communication.setReadGroupMessages(params, (err, res) => {
                if (err) return;
                const communicationGroup = { ...group };
                communicationGroup.messages = messages.map((msg) => {
                    const message = msg;
                    message.readBy.push(groupMemberId);
                    return message;
                });
                v.$store.commit(Types.mutations.SET_DB_COMMUNICATION_GROUPS, [communicationGroup]);
                v.$store.commit(Types.mutations.SET_SELECTED_COMMUNICATION_GROUP, communicationGroup);
            });
        },
        getGroupMembers() {
            const v = this;
            const { user, group } = v;
            if (!group) return;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { communicationGroupId } = group;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    communicationGroupId,
                },
            };
            network.communication.getMembersForGroup(params, (err, res) => {
                if (err) return v.showError(err);
                const { members } = res;
                v.groupMembers = members;
            });
        },
        sendMessage() {
            const v = this;
            const {
                saving, user, group, groupMemberId, newMessage,
            } = v;
            if (saving || !group || !newMessage) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { communicationGroupId } = group;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    communicationGroupId,
                },
                body: {
                    params: {
                        communicationGroupMemberId: groupMemberId,
                        message: newMessage,
                        readBy: [groupMemberId],
                        notifySms: false,
                    },
                },
            };
            network.communication.sendGroupMessage(params, (err, res) => {
                v.saving = false;
                v.scrollToChatEnd();
                if (err) return v.showError('Unable to send message');
                v.newMessage = '';
                const { messages } = res;
                const communicationGroup = { ...group };
                communicationGroup.messages.push(...messages);
                v.$store.commit(Types.mutations.SET_DB_COMMUNICATION_GROUPS, [communicationGroup]);
            });
        },
        saveGroupChanges() {
            const v = this;
            const {
                saving, group, user, newGroupName, newMembers,
            } = v;
            if (saving || !group) return;
            v.saving = true;

            const { communicationGroupId } = group;
            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { schoolStaffId, studentGuardianId, studentId } = school;

            async.auto({
                groupName(next) {
                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                            communicationGroupId,
                        },
                        body: {
                            params: {
                                schoolStaffId: schoolStaffId || null,
                                studentGuardianId: studentGuardianId || null,
                                studentId: studentGuardianId ? null : studentId || null,
                                groupName: newGroupName || null,
                            },
                        },
                    };
                    network.communication.renameGroup(params, (err, res) => {
                        if (err) return next(err);
                        next(null, res);
                    });
                },
                members(next) {
                    if (!newMembers.length || !schoolStaffId) return next();

                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                            schoolStaffId,
                            communicationGroupId,
                        },
                        body: {
                            members: newMembers.map((m) => ({ ...m, groupName: newGroupName || null })),
                        },
                    };

                    network.communication.addGroupMembers(params, (err, res) => {
                        if (err) return next(err);
                        next(null, res.groupMembers);
                    });
                },
            }, (err, results) => {
                v.saving = false;
                if (err) return v.showError(err);
                v.newMembers = [];
                const members = results.members || [];
                const communicationGroup = { ...group, groupName: newGroupName || null, members: [...group.members, ...members] };
                v.$store.commit(Types.mutations.SET_DB_COMMUNICATION_GROUPS, [communicationGroup]);
                v.$store.commit(Types.mutations.SET_SELECTED_COMMUNICATION_GROUP, communicationGroup);
            });
        },
        memberAdded(member) {
            const {
                schoolStaffId, studentEnrollmentId, studentGuardianId,
            } = member;

            const { newMembers } = this;
            let found = null;
            if (schoolStaffId) found = newMembers.find((u) => u.schoolStaffId == schoolStaffId);
            else if (studentGuardianId) found = newMembers.find((u) => u.studentGuardianId == studentGuardianId);
            else if (studentEnrollmentId) found = newMembers.find((u) => u.studentEnrollmentId == studentEnrollmentId && u.role !== 'Guardian');

            if (!found) newMembers.push(member);
        },
        removeUser(idx) {
            const { newMembers } = this;
            newMembers.splice(idx, 1);
        },
        closePopupChat() {
            this.$store.commit(Types.mutations.SET_SELECTED_COMMUNICATION_GROUP, null);
            this.$store.commit(Types.mutations.TOGGLE_COMMUNICATION_POP_UP_CHAT);
        },
        setGroupName() {
            const { group, altGroupName } = this;
            if (!group) return;
            this.newGroupName = group.groupName || altGroupName;
        },
        scrollToChatEnd() {
            const { messages } = this;
            if (!messages.length) return;
            this.$nextTick(() => {
                const lastChat = document.getElementById('chat');
                if (lastChat) lastChat.scrollTop = lastChat.scrollHeight;
            });
        },
    },
};
</script>

<style scoped>
div.scroll-list {
    height: 480px !important;
    overflow-y: scroll !important;
    overflow-x: clip !important;
}

.chat-group-name {
    display: inline-block !important;
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    line-height: 1.2em !important;
    height: 3.6em !important;
    max-height: 3.6em !important;
    overflow: hidden !important;
}
</style>
