var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _vm.group && !_vm.showChatSettings
      ? _c("div", { staticClass: "kt-chat" }, [
          _c(
            "div",
            {
              staticClass:
                "kt-portlet kt-portlet--height-fluid kt-portlet--head-lg",
            },
            [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-chat__head" }, [
                  _c("div", { staticClass: "kt-chat__left" }, [
                    _vm.isPopUp
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-clean btn-sm btn-icon btn-icon-md",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.closePopupChat.apply(null, arguments)
                              },
                            },
                          },
                          [_c("i", { staticClass: "la la-close" })]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "kt-chat__center" }, [
                    _c("div", { staticClass: "kt-chat__label" }, [
                      _c(
                        "a",
                        {
                          staticClass: "kt-chat__title",
                          class: {
                            "chat-group-name":
                              (_vm.group.groupName &&
                                _vm.group.groupName.length > 200) ||
                              _vm.altGroupName.length > 200,
                          },
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.group.groupName || _vm.altGroupName)
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "kt-chat__status" }, [
                        _vm._v(
                          " " + _vm._s(_vm.group.members.length) + " Members "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-chat__right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-clean btn-sm btn-icon btn-icon-md",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showChatSettings = !_vm.showChatSettings
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa fa-cog" })]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c(
                  "div",
                  {
                    staticClass: "kt-scroll",
                    staticStyle: {
                      height: "244px",
                      "overflow-x": "hidden",
                      "overflow-y": "auto",
                    },
                    attrs: { id: "chat" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "kt-chat__messages" },
                      _vm._l(_vm.messages, function (message, idx) {
                        return _c(
                          "div",
                          {
                            key: `message_${message.communicationGroupMessageId}_${idx}`,
                            staticClass: "kt-chat__message",
                            class: {
                              "kt-chat__message--right": message.fromMe,
                            },
                          },
                          [
                            message.fromMe
                              ? _c("div", { staticClass: "kt-chat__user" }, [
                                  _c(
                                    "span",
                                    { staticClass: "kt-chat__datetime" },
                                    [_vm._v(_vm._s(message.createdAt))]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-chat__username",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          message.user
                                            ? `${message.user.firstName} ${message.user.lastName}`
                                            : "Unknown User"
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-media kt-media--circle kt-media--sm",
                                    },
                                    [
                                      _c(
                                        "UserAvatar",
                                        {
                                          staticStyle: {
                                            "max-width": "40px",
                                            width: "40px",
                                            height: "40px",
                                          },
                                          attrs: {
                                            "avatar-user": message.user || {},
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--lg kt-badge--success",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    message.user
                                                      ? `${message.user.lastName.substring(
                                                          0,
                                                          2
                                                        )}`
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", { staticClass: "kt-chat__user" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-media kt-media--circle kt-media--sm",
                                    },
                                    [
                                      _c(
                                        "UserAvatar",
                                        {
                                          staticStyle: {
                                            "max-width": "40px",
                                            width: "40px",
                                            height: "40px",
                                          },
                                          attrs: {
                                            "avatar-user": message.user || {},
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--lg kt-badge--success",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    message.user
                                                      ? `${message.user.lastName.substring(
                                                          0,
                                                          2
                                                        )}`
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-chat__username",
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          message.user
                                            ? `${message.user.firstName} ${message.user.lastName}`
                                            : "Unknown User"
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "kt-chat__datetime" },
                                    [_vm._v(_vm._s(message.createdAt))]
                                  ),
                                ]),
                            _c(
                              "div",
                              {
                                staticClass: "kt-chat__text",
                                class: {
                                  "kt-bg-light-brand": message.fromMe,
                                  "kt-bg-light-success": !message.fromMe,
                                },
                              },
                              [_vm._v(" " + _vm._s(message.message) + " ")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._m(0),
                    _vm._m(1),
                  ]
                ),
              ]),
              _c("div", { staticClass: "kt-portlet__foot" }, [
                _c("div", { staticClass: "kt-chat__input" }, [
                  _c("div", { staticClass: "kt-chat__editor" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newMessage,
                          expression: "newMessage",
                        },
                      ],
                      staticStyle: { height: "50px" },
                      attrs: { placeholder: "Type here..." },
                      domProps: { value: _vm.newMessage },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newMessage = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "kt-chat__toolbar" }, [
                    _c("div", { staticClass: "kt_chat__tools" }),
                    _c("div", { staticClass: "kt_chat__actions" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-brand btn-md btn-upper btn-bold kt-chat__reply",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.sendMessage.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Send ")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ])
      : _vm.group && _vm.showChatSettings
      ? _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
          _c(
            "div",
            { staticClass: "kt-portlet__head kt-portlet__head--noborder" },
            [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-clean",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.showChatSettings = !_vm.showChatSettings
                      },
                    },
                  },
                  [_vm._v(" Back to Chat ")]
                ),
              ]),
              _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _c("div", { staticClass: "kt-portlet__head-actions" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-clean btn-bold",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.saveGroupChanges.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "kt-portlet__body scroll-list" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Change Group Name")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newGroupName,
                    expression: "newGroupName",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Group Name" },
                domProps: { value: _vm.newGroupName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.newGroupName = $event.target.value
                  },
                },
              }),
            ]),
            _vm.isGroupOwner
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("Add New Members")]),
                      _c("SearchRecipients", {
                        attrs: {
                          "on-selected": _vm.memberAdded,
                          "school-staff-id": _vm.schoolStaffId,
                          "student-enrollment-id": null,
                          "allow-school-wide": false,
                          "allow-my-students": false,
                          "show-courses": false,
                          "show-home-rooms": false,
                          "show-grade-levels": false,
                          "show-student-groups": false,
                          "show-students": false,
                          "show-guardians": false,
                          "show-teachers": true,
                        },
                      }),
                      _c("div", { staticClass: "tab-content p-3" }, [
                        _c(
                          "div",
                          { staticClass: "kt-widget4" },
                          _vm._l(_vm.newMembers, function (user, idx) {
                            return _c(
                              "div",
                              {
                                key: `new_group_user_${idx}`,
                                staticClass: "kt-widget4__item",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.removeUser(idx)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "la la-close" })]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-widget4__pic kt-widget4__pic--pic",
                                  },
                                  [
                                    _c(
                                      "UserAvatar",
                                      { attrs: { "avatar-user": user || {} } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-badge kt-badge--lg kt-badge--success",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  user
                                                    ? user.firstName.substring(
                                                        0,
                                                        2
                                                      )
                                                    : "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "kt-widget4__info" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-widget4__username",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeUser(idx)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(user.name) + " ")]
                                  ),
                                  _c("p", { staticClass: "kt-widget4__text" }, [
                                    _vm._v(" " + _vm._s(user.subName) + " "),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-group" }, [
              _c("div", [
                _c("label", [_vm._v("Current Members")]),
                _c("div", { staticClass: "tab-content p-3" }, [
                  _c(
                    "div",
                    { staticClass: "kt-widget4" },
                    _vm._l(_vm.members, function (member, idx) {
                      return _c(
                        "div",
                        {
                          key: `group_userlist_user_${idx}`,
                          staticClass: "kt-widget4__item",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-widget4__pic kt-widget4__pic--pic",
                            },
                            [
                              _c(
                                "UserAvatar",
                                { attrs: { "avatar-user": member.user || {} } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--lg kt-badge--success",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            member.user && member.user.lastName
                                              ? member.user.lastName.substring(
                                                  0,
                                                  2
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "kt-widget4__info" }, [
                            _c(
                              "a",
                              {
                                staticClass: "kt-widget4__username",
                                attrs: { href: "#" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      member.user
                                        ? `${member.user.firstName || ""} ${
                                            member.user.lastName || ""
                                          }`
                                        : "Unknown Member"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("p", { staticClass: "kt-widget4__text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    member.user ? member.user.role || "" : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "kt-chat" }, [_vm._m(2)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ps__rail-x",
        staticStyle: { left: "0px", bottom: "0px" },
      },
      [
        _c("div", {
          staticClass: "ps__thumb-x",
          staticStyle: { left: "0px", width: "0px" },
          attrs: { tabindex: "0" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ps__rail-y",
        staticStyle: { top: "0px", height: "244px", right: "-2px" },
      },
      [
        _c("div", {
          staticClass: "ps__thumb-y",
          staticStyle: { top: "0px", height: "48px" },
          attrs: { tabindex: "0" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-portlet kt-portlet--height-fluid kt-portlet--head-lg",
      },
      [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-chat__head" }, [
            _c("div", { staticClass: "kt-chat__left" }),
            _c("div", { staticClass: "kt-chat__center" }, [
              _c("div", { staticClass: "kt-chat__label" }, [
                _c(
                  "a",
                  { staticClass: "kt-chat__title", attrs: { href: "#" } },
                  [_vm._v("No Group Selected")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "kt-chat__right" }),
          ]),
        ]),
        _c("div", {
          staticClass: "kt-portlet__body",
          staticStyle: { height: "480px" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }